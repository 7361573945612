import { useEffect, useContext } from "react"
import { useNavigate } from 'react-router-dom'
import { useImmer } from "use-immer"
//import placeholder from '../placeholder.png'
import logo from '../logo.png'
import net from '../net.png'
import deal from '../deal.png'
import { Tooltip, Button, Space } from "antd"
import { LogoutOutlined } from '@ant-design/icons'
import Axios from "axios"
import api from "../AxiosInstance"

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'

function Header(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const navigate = useNavigate()

    const [logoutData, setLogoutData] = useImmer({requestCount:0, })

    useEffect(() => {
        if (logoutData.requestCount === 0) return

        async function reqLogout() {
            //console.log(appState.token.post_config)
            try {
                const response = await api.post('token/logout/', appState.token.value, appState.token.post_config)
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to logout!"
                })
            }
            appDispatch({type: "logout", })
            navigate('/')
            window.location.reload(true)
        }
        reqLogout()
    }, [logoutData.requestCount])

    function home() {
        navigate('/')
        window.location.reload(true)
    }

    function logout() {
        setLogoutData(draft => {
            draft.requestCount++
        })
    }

    if (!appState.loggedIn) return <></>

    return (
        <div className='Header'>
            <img src={net} style={{width:'32px', height:'32px', paddingLeft:'6px', zIndex: 99}} />
            <img src={deal} style={{width:'32px', height:'32px', marginLeft: '-6px', zIndex: -1}} />
            <img src={logo} onClick={home} className="logo" alt="logo" />
            <Space style={{marginLeft: 'auto', paddingRight:'17px'}}>
                <strong>{appState.user.username}</strong>
                <Tooltip
                    placement="bottom"
                    title={<span>Logout</span>}
                >
                    <Button
                        className="btn-style"
                        onClick={logout}
                        icon={<LogoutOutlined />}
                    ></Button>
                </Tooltip>
            </Space>
        </div>
    )
}

export default Header