import { Button, Space, Badge, Spin } from "antd"
import { RedoOutlined } from '@ant-design/icons'


function DealerConnStatus(props) {

    let color = props.connected ? "#52c41a" : "#f5222d"

    return (
        <div className='grid-item'>
            {/*<Badge count={" "} size="small" color={props.connected ? "#52c41a" : "#f5222d"}>
                <div style={{width:"100%"}}></div>
            </Badge>*/}
            <div
                style={{
                    width: '16px',
                    height: '16px',
                    backgroundColor: color,
                    borderRadius: '50%',
                    display: 'inline-block',
                }}
            >
            </div>
            <p>{props.dealer.full_name}</p>
            <p>{props.dealer.phone_number}</p>
            <div>
                <h4>{props.header}</h4>
                <p id="msg" className='withprewrap'>
                    {props.message}
                </p>
                {props.qrCode === "" || props.qrCode === undefined || props.connected ? "" : 
                <Space direction="vertical">
                    <img src={props.qrCode} alt="QR Code" id="qrcode" width={30}/>
                    {/*<Button
                        className='btn-style-red'
                        style={{marginLeft: '14px', width:'272px'}}
                        onClick={props.onRefreshClick(props.dealer.phone_number)}
                        //size="small"
                        icon={<RedoOutlined />}
                    >
                        Refresh QR Code
                    </Button>*/}
                </Space>
                }
            </div>
        </div>
    )
}

export default DealerConnStatus