import { Card, Space } from 'antd'
import { getLocalTime } from "../Utils"


function ReachScreenOffers(props) {

    function listItems(arr) {
        if (arr.length === 0) {
            return <p>---</p>
        }
        const res = arr.map((item, index) => {
            return (
                <div key={index} style={{backgroundColor:'whitesmoke'}}>
                    <div style={{fontWeight:'bold'}}>{`> ${item[1]}`}</div>
                    <span style={{marginLeft:'16px'}}>{item[0]}</span>
                    <div style={{height:'6px'}} />
                </div>
            )
        })
        return res
    }

    function renderOffers() {
        const userToOfferMap = props.getIndividualOffers(props.dataSource.selectedItemIndex)

        const reachsComponent = Object.entries(userToOfferMap).map((item, index) => {
            const numGroups = item[1].groups.length
            const numDMs = item[1].dms.length
            return (
                <Card
                    key={index}
                    title={`${item[0]} (${numGroups+numDMs})`}
                    style={{
                        width: 300,
                    }}
                    >
                    <strong style={{backgroundColor: numGroups < props.dataSource.minNumGroups ? "#FF4D4F" : "#73D673", padding:5, borderRadius:3}}>{`Groups (${numGroups})`}</strong>
                    <div style={{height:'8px'}} />
                    <div style={{maxHeight:'300px', overflow:'auto'}}>{listItems(item[1].groups)}</div>
                    <div style={{height:'16px'}} />
                    <strong style={{backgroundColor: numDMs < props.dataSource.minNumDMs ? "#FF4D4F" : "#73D673", padding:5, borderRadius:3}}>{`DMs (${numDMs})`}</strong>
                    <div style={{height:'8px'}} />
                    <div style={{maxHeight:'300px', overflow:'auto'}}>{listItems(item[1].dms)}</div>
                </Card>
            )
        })

        return reachsComponent
    }

    return (
        
        <div style={{display: 'flex', flexDirection:'column', paddingLeft:'10px', paddingTop:'10px'}}>
            <div className='grid-container-offers'>
                {renderOffers()}
            </div>
        </div>
    )
}

export default ReachScreenOffers