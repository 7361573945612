import { Modal, Button } from "antd"

function GenericWarningModal(props) {

    function closeModal() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        props.setDataSource(draft => {
            draft.isLoading = false
        })
    }

    function handleOk() {
        closeModal()
    }

    function handleCancel() {
        closeModal()
    }

    function footer() {
        if (props.notDisplayOkButton)
            return ""
        return [
            <Button key="submit" type="primary" onClick={handleOk}>
                OK
            </Button>,
        ]
    }

    return (
        <Modal
            centered
            title=""
            open={props.state.modal.isOpen}
            onCancel={handleCancel}
            footer={footer()}
            >
                <span className='withprewrap'>{props.state.title}</span>
        </Modal>
    )
}

export default GenericWarningModal
