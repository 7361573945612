import { useContext, useEffect } from 'react'
import { useImmer } from "use-immer"
import { Spin } from "antd"
import { io } from 'socket.io-client'
import { useFetchDealers } from './DataFetcher'
import SideMenu from './SideMenu'
import Login from "./Login"

import { cropText, getLocalTime } from '../Utils'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'
import DealerConnStatus from './DealerConnStatus'

import { SOCKET_URL, SOCKET_PATH, NODES } from "../Settings"


function WhatsAppAdmin(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [filterOptions, setFilterOptions] = useImmer({
        dealers:{data:[], requestCount:0, isLoading:false},
    })

    const [waState, setWaState] = useImmer({
        header: {},
        message: {},
        qrCode: {},
        refreshQrId: null,
        connected: {},
    })

    function onRefreshClick(id) {
        /*setWaState(draft => {
            draft.refreshQrId = id
        })*/
    }

    useEffect(() => {
        if (!appState.loggedIn) return
        if (appState.user === null || appState.user.phonenumber === null) return
        if (appState.requestCounter === 0) return

        //console.log(appState.user)

        /* Socket */
        let sockets = []
        if (SOCKET_URL.includes("localhost")) {
            sockets.push(io(SOCKET_URL))
        }  else {
            for (let node of NODES) {
                sockets.push(io(SOCKET_URL, {path: "/" + node + SOCKET_PATH, }))
            }
        }

        for (let i = 0; i < sockets.length; i++) {

            let socket = sockets[i]
            let node = NODES[i]

            socket.on('connect', () => {
                //console.log(socket.id)
                props.setSocketState(draft => {
                    draft.data[node] = "connected"
                })
                /*if (appState.user.phonenumber !== null) {
                    if (waState.refreshQrId !== null) {
                        //socket.emit("destroy-client", {id: waState.refreshQrId,}) //token: state.token.value
                        setWaState(draft => {
                            draft.refreshQrId = null
                        })
                        return
                    } else {
                        socket.emit("client-data", {id: "admin",}) //token: state.token.value
                    }
                }*/
                socket.emit("client-data", {id: "admin",}) //token: state.token.value
            })
        
            socket.on('connect_error', () => {
                //console.log('connect_error')
                props.setSocketState(draft => {
                    draft.data[node] = "connection error"
                })
                /*filterOptions.dealers.data.forEach((dealer) => {
                    setWaState(draft => {
                        draft.connected[dealer.phone_number.substring(1)] = false
                    })
                })*/
            })
        
            socket.on('disconnect', () => {
                props.setSocketState(draft => {
                    draft.data[node] = "disconnected"
                })
                /*filterOptions.dealers.data.forEach((dealer) => {
                    setWaState(draft => {
                        draft.connected[dealer.phone_number.substring(1)] = false
                    })
                })*/
            })
    
            /*socket.on('restart', () => {
                socket.emit("client-data", {id: state.user.phonenumber, token: state.token.value})
            })*/
    
            /* WhatsApp */
            socket.on('qr', (data) => {
                const id = data.id
                if (waState.connected[id]) return
                setWaState(draft => {
                    draft.qrCode[id] = data.qrCode
                    draft.header[id] = data.message
                    draft.message[id] = ""
                    //draft.connected[id] = false
                })
            })
    
            socket.on('loading', (data) => {
                const id = data.id
                setWaState(draft => {
                    draft.qrCode[id] = ""
                    draft.header[id] = data.message
                    draft.message[id] = ""
                    //draft.connected[id] = false
                })
            })
    
            socket.on('ready', (data) => {
                const id = data.id
                setWaState(draft => {
                    draft.qrCode[id] = ""
                    draft.header[id] = data.message
                    draft.message[id] = ""
                    draft.connected[id] = true
                })
            })
    
            socket.on('authenticated', (data) => {
                const id = data.id
                setWaState(draft => {
                    draft.qrCode[id] = ""
                    draft.header[id] = data.message
                    draft.message[id] = ""
                    //draft.connected[id] = true
                })
            })
    
            socket.on('auth_failure', (data) => {
                const id = data.id
                setWaState(draft => {
                    draft.qrCode[id] = ""
                    draft.header[id] = data.message
                    draft.message[id] = ""
                    draft.connected[id] = false
                })
            })
    
            socket.on('disconnected', (data) => {
                const id = data.id
                setWaState(draft => {
                    draft.qrCode[id] = ""
                    draft.header[id] = data.message
                    draft.message[id] = ""
                    draft.connected[id] = false
                })
            })
    
            socket.on('message', (data) => {
                const id = data.id
                setWaState(draft => {
                    draft.qrCode[id] = ""
                    draft.header[id] = cropText([getLocalTime(data.msg_dict.time, true), data.msg_dict.from, data.msg_dict.name].join(", "), 25)
                    draft.message[id] = cropText(data.msg_dict.body, 5)
                    draft.connected[id] = true
                })
            })
    
            socket.on('conn_state', (data) => {
                const id = data.id
                if (data.state) {
                    if (data.message !== undefined) {
                        setWaState(draft => {
                            draft.qrCode[id] = ""
                            draft.header[id] = cropText([getLocalTime(data.message.time, true), data.message.from, data.message.name].join(", "), 25)
                            draft.message[id] = cropText(data.message.body, 5)
                            draft.connected[id] = data.state
                        })
                    } else {
                        setWaState(draft => {
                            draft.qrCode[id] = ""
                            draft.header[id] = "WhatsApp is connected!"
                            draft.message[id] = ""
                            draft.connected[id] = data.state
                        })
                    }
                } else {
                    setWaState(draft => {
                        draft.qrCode[id] = ""
                        draft.header[id] = "WhatsApp is not connected! Please wait to receive QR code.."
                        draft.message[id] = ""
                        draft.connected[id] = data.state
                    })
                }
            })
        }
    }, [appState.requestCounter, /*waState.refreshQrId*/])

    /*** FETCH DATA ***/
    useFetchDealers(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher

    if (!appState.loggedIn) return <Login />

    if (filterOptions.dealers.isLoading) return (
        <div style={{display: 'flex', alignItems:'center', justifyContent:'center', height:'100vh'}}>
            <Spin size='large' />
        </div>
    )

    function getStatuses() {
        const statuses = filterOptions.dealers.data.map((item, index) => {
            const id = item.phone_number.substring(1)
            //console.log(waState.header[id], waState.message[id], waState.qrCode[id], waState.connected[id])
            return <DealerConnStatus
                key={index}
                header={waState.header[id]}
                message={waState.message[id]}
                qrCode={waState.qrCode[id]}
                connected={waState.connected[id]}
                dealer={item}
                onRefreshClick={onRefreshClick}
            />
        })
        return statuses
    }

    function checkSocketState() {
        /*for (let node of NODES) {
            if (props.socketState.data[node] !== "connected") {
                return false
            }
        }*/
        return true
    }

    return (
        <div className='App'>
            <SideMenu connected={checkSocketState()} />
            <div style={{display: 'flex', flexDirection:'column', paddingLeft:'10px', paddingTop:'10px'}}>
                {checkSocketState() ? <div className='grid-container'>
                    {getStatuses()}
                </div> : ""}
                <small style={{paddingLeft:'12px', paddingTop:'5px'}}>{"socket: "}{JSON.stringify(props.socketState.data)}</small>
            </div>
        </div>
    )
}

export default WhatsAppAdmin